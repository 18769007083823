@import "../../global.scss";

.header_layout{
    position: fixed;
    left: 80px;
    right: 80px;
    top: 30px;
    height: 60px;

    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 1;
}

.header_logo{
    height: 30px;
}

.buttons_top_container{
    height: 100%;

    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 30px;
}

.small_buttons_container{
    height: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 15px;
}

.border_box{
    width: 2px;
    height: 100%;
    background-color: #ff77b9;
    box-shadow: 10px 10px 3px #00000029;
}

@media screen and (max-width: 1200px){
    .header_logo{
        height: 45px;
    }

    .header_layout{
        left: 20px;
        right: 20px;
    }

    .menu_icon{
        height: 30px;
        width: 40px;
        z-index: 20;
    }

    .menu_mobile{
        position: fixed;
        top: 0px;
        bottom: 0px;
        left: 0px;
        right: 0px;
        background:  transparent linear-gradient(180deg, #ff77b9bb 0%, #0000fcc5 100%) 0% 0% no-repeat padding-box;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 30px;
        a{
            font-size: 30px;
            color: white;
            font-family: 'Helvetica Neue';
        }
    }
}