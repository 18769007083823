@import "../../global.scss";

.main_button{
    @extend .unselectable;
    
    background: #FFF201;
    box-shadow: 10px 10px 3px #00000029;
    width: 220px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Helvetica Neue';
    font-weight: 900;
    font-size: 22px;
}

@media screen and (max-width: 1200px){
    .main_button{
        height: 35px;
        width: 160px;
        font-size: 17px;
        border-radius: 35px;
    }
}