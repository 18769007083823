.information_box{
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    overflow-x: hidden;
}

.info1_box{
    position: absolute;
    top: 15%;
    left: 10%;
    display: flex;
    flex-direction: column;
}

.info1_title{
    color: white;
    font-family: 'Helvetica Neue';
    font-weight: 900;
    font-size: 22px;
    width: 350px;
    margin-bottom: 15px;
}

.info1_inner_box{
    display: flex;
    gap: 15px;
}

.info1_icon{
    background-image: url('../../../assets/icons/earningPictosIcon.svg');
    background-size: contain;
    background-repeat: no-repeat;
    width: 100px;
    height: 100px;
}

.info1_text1{
    color: white;
    font-family: 'Helvetica Neue';
    font-weight: 300;
    font-size: 20px;
    width: 230px;
    text-align: left;
}

.info1_text2{
    color: white;
    font-family: 'Helvetica Neue';
    font-weight: 300;
    font-size: 20px;
    width: 350px;
    text-align: left;
}

.info2{
    position: absolute;
    bottom: calc(50% - (62.44vw * 0.2 ));
    left: calc(50vw - 400px);
    transform: translate(-50%,0);
    color: white;
    font-family: 'Helvetica Neue';
    font-weight: 900;
    font-size: 24px;
    text-align: center;
    width: 800px;
}

@media screen and (max-width: 1200px){
    .information_box{
        padding: 30px;
        padding-top: 120px;
        padding-bottom: 100px;
        display: flex;
        flex-direction: column;
        gap: 80px;
    }
    .info1_box{
        position: relative;
        top: 0;
        right: 0;
        left: 0;
    }
    .info1_title{
        width: 100%;
    }
    .info1_text1{
        width: calc(100% - 100px);
    }
    .info1_text2{
        width: 100%;
    }
    .info2_icon{
        background-image: url('../../../assets/icons/whyExceptionalIcon.svg');
        background-size: contain;
        background-repeat: no-repeat;
        width: 90px;
        height: 9cap;
    }
    .info2_text1{
        width: calc(100% - 90px);
        color: white;
        font-family: 'Helvetica Neue';
        font-weight: 300;
        font-size: 20px;
    }
    .info2_text2{
        width: 100%;
        color: white;
        font-family: 'Helvetica Neue';
        font-weight: 300;
        font-size: 20px;
        text-align: justify;
    }
}