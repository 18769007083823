.page_container{
    flex: 1;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    h1{
        color: white;
        font-family: 'Helvetica Neue';
        font-weight: 900;
        font-size: 50px;
    }

    p{
        color: white;
        font-family: 'Helvetica Neue';
        font-weight: 300;
        font-size: 20px;
    }
}