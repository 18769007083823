.graphics_box{
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: -2;
    overflow-x: clip;
}

.element1{
    position: absolute;
    background: url('./elements/element1.svg');
    top: 8.2%;
    left: -0.76%;
    width: 16.2%;
    height: 21.25%;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}

.element2{
    position: absolute;
    background: url('./elements/element2.svg');
    top: 41.67%;
    left: 44.4%;
    width: 18.2%;
    height: 10.96%;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}

.element3{
    position: absolute;
    background: url('./elements/element3.svg');
    top: 48.43%;
    left: 93.33%;
    width: 5.16%;
    height: 10%;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}

.element4{
    position: absolute;
    background: url('./elements/element4.svg');
    top: 59.91%;
    left: 3.77%;
    width: 10.43%;
    height: 28.24%;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}

.element5{
    position: absolute;
    background: url('./elements/element5.svg');
    bottom:  calc(110.87vw * -0.04);
    left: -5%;
    width: 110.87%;
    height: calc(110.87vw * 0.3);
    background-repeat: no-repeat;
    z-index: -10;
    background-size: contain;
    background-position: center;
}

@media screen and (max-width: 1200px){
    .element5{
        width: 200%;
        height: calc(200vw * 0.3);
        left: -70%;
    }
}