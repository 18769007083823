@import "../../global.scss";

.top_container{
    height: 50vh;
    overflow: hidden;
}

.container{
    position: relative;
    height: 450px;
    width: 1000px;
    overflow: clip;
    transform-origin: 0 0;
}

.top_circle{
    position: absolute;
    width: 330px;
    height: 100px;
    z-index: -1;
}
.top_circle::after{
    content: "";
    position: absolute;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    width: 315px;
    height: 100px;
    left: 7.5px;
    border-color: #fff301;
    border-radius: 85px 85px 0px 0px;
    border-width: 15px;
    border-style: solid;
    border-bottom: 0px;
}

.top_circle_dashed{
    position: absolute;
    width: 330px;
    height: 100px;
    z-index: -1;
}
.top_circle_dashed::after{
    content: "";
    position: absolute;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    width: 305px;
    height: 100px;
    left: 12.5px;
    border-color: #fff301;
    border-radius: 85px 85px 0px 0px;
    border-width: 3px;
    border-style: dashed;
    border-bottom: 0px;
    z-index: -1;
}

.top_last{
    position: absolute;
    width: 430px;
    height: 100px;
    z-index: -1;
}
.top_last::after{
    content: "";
    position: absolute;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    width: 405px;
    height: 100px;
    left: 12.5px;
    border-color: #fff301;
    border-radius: 85px 0px 0px 0px;
    border-width: 3px;
    border-style: dashed;
    border-bottom: 0px;
    border-right: 0px;
    z-index: -1;
}


.bottom_circle{
    position: absolute;
    width: 330px;
    height: 100px;
    z-index: -1;
}
.bottom_circle::after{
    content: "";
    position: absolute;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    width: 315px;
    height: 100px;
    left: 7.5px;
    border-color: #fff301;
    border-radius: 0px 0px 85px 85px;
    border-width: 15px;
    border-style: solid;
    border-top: 0px;
}

.bottom_circle_dashed{
    position: absolute;
    width: 330px;
    height: 100px;
    z-index: -1;
}
.bottom_circle_dashed::after{
    content: "";
    position: absolute;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    width: 305px;
    height: 100px;
    left: 12.5px;
    border-color: #fff301;
    border-radius: 0px 0px 85px 85px;
    border-width: 3px;
    border-style: dashed;
    border-top: 0px;
    z-index: -1;
}

.vertical_line{
    position: absolute;
    width: 30px;
    height: 95px;
    z-index: -1;
}
.vertical_line::after{
    content: "";
    position: absolute;
    width: 15px;
    background-color: #fff301;
    left: 7.5px;
    height: 95px;
}

.vertical_line_dashed{
    position: absolute;
    width: 30px;
    height: 95px;
    z-index: -1;
}
.vertical_line_dashed::after{
    content: "";
    position: absolute;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    width: 0px;
    border-color: #fff301;
    border-width: 3px;
    border-style: dashed;
    border-bottom: 0;
    border-top: 0;
    border-left: 0;
    left: 12.5px;
    height: 95px;
}
.completed_circle{
    position: absolute;
    width: 20px;
    height: 20px;
    background-color: #ff77b9;
    border-radius: 20px;
    border-width: 5px;
    border-style: solid;
    border-color: black;
}

.uncompleted_circle{
    position: absolute;
    width: 20px;
    height: 20px;
    border-radius: 20px;
    border-width: 5px;
    border-style: solid;
    border-color:black;
}
.uncompleted_circle::after{
    content: "";
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    position: absolute;
    width: 20px;
    height: 20px;
    border-radius: 20px;
    border-width: 2px;
    border-style: solid;
    border-color: #707070;
    background-color: black;
}

.title{
    position: absolute;
    color: white;
    font-family: 'Helvetica Neue';
    font-weight: 900;
    font-size: 22px;
    margin-left: 40px;
    @extend .unselectable;
}

.text{
    position: absolute;
    color: white;
    font-family: 'Helvetica Neue';
    width: 250px;
    margin-left: 40px;
    font-weight: 300;
    font-size: 22px;
    @extend .unselectable;
}