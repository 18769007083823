.page_container{
    width: 100%;
    height: 100vh;
    position: relative;
}

.title{
    position: absolute;
    width: 100%;
    height: 40px;
    top: 12%;
    left: 0;
    color: white;
    text-align: center;
    font-family: 'Helvetica Neue';
    font-weight: 900;
    font-size: 58px;
}

.partners_container{
    position: absolute;
    left: 5%;
    top: 25%;
    right: 5%;
    bottom: 10%;
    display: flex;
    flex-wrap:wrap;
    gap:20px;
    justify-content: center;
    align-items: center;
}

.partner{
    width: 300px;
    height: 150px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}

@media screen and (max-width: 1200px){
    .title{
        top: 100px;
        font-size: 38px;
    }
    .partners_container{
        top: 150px;
        bottom: 100px;
        left: 20px;
        right: 20px;
    }

    .partner{
        width: 150px;
        height: 80px;
    }
}