.page_container {
    flex: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 120px 20px 40px;
    overflow-y: auto;
    box-sizing: border-box;
}

.title {
    font-size: 42px;
    font-weight: 900;
    color: white;
    font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif;
    margin-bottom: 30px;
    text-align: center;
    letter-spacing: -0.03em;
}

.content {
    max-width: 800px;
    width: 100%;
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(10px);
    padding: 40px;
    border-radius: 20px;
    color: white;
    font-family: 'SF Pro Text', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif;
    line-height: 1.6;
    box-sizing: border-box;

    .effective_date {
        font-size: 18px;
        color: rgba(255, 255, 255, 0.9);
        margin-bottom: 30px;
        text-align: center;
        font-weight: 600;
        letter-spacing: -0.01em;
    }

    .intro {
        font-size: 18px;
        margin-bottom: 30px;
        line-height: 1.8;
        color: rgba(255, 255, 255, 0.9);
        font-weight: 400;
        letter-spacing: -0.01em;
    }

    .warning {
        background: rgba(239, 68, 68, 0.1);
        border-left: 4px solid #EF4444;
        padding: 15px 20px;
        margin: 30px 0;
        font-weight: 500;
        color: #EF4444;
    }

    section {
        margin: 40px 0;

        &:first-of-type {
            margin-top: 0;
        }

        &:last-of-type {
            margin-bottom: 0;
        }
    }

    h2 {
        font-size: 24px;
        font-weight: 700;
        color: white;
        margin-bottom: 20px;
        letter-spacing: -0.02em;
    }

    p {
        margin-bottom: 15px;
        font-size: 16px;
        line-height: 1.8;
        color: rgba(255, 255, 255, 0.9);
        letter-spacing: -0.01em;

        &:last-child {
            margin-bottom: 0;
        }
    }

    ul, ol {
        margin: 15px 0;
        padding-left: 20px;

        li {
            margin: 10px 0;
            line-height: 1.6;
            color: rgba(255, 255, 255, 0.9);
            padding-left: 10px;
            letter-spacing: -0.01em;

            &::marker {
                color: rgba(255, 255, 255, 0.6);
            }
        }
    }

    a {
        color: #0066cc;
        text-decoration: none;
        transition: all 0.2s ease;
        position: relative;

        &:hover {
            color: #0052a3;
            text-decoration: none;
            
            &:after {
                width: 100%;
            }
        }

        &:after {
            content: '';
            position: absolute;
            width: 0;
            height: 1px;
            bottom: -2px;
            left: 0;
            background-color: #0052a3;
            transition: width 0.2s ease;
        }
    }
}

@media screen and (max-width: 768px) {
    .page_container {
        padding: 100px 16px 32px;
    }

    .title {
        font-size: 32px;
        margin-bottom: 24px;
    }

    .content {
        padding: 24px;
        border-radius: 16px;

        .effective_date {
            font-size: 16px;
            margin-bottom: 24px;
        }

        .intro {
            font-size: 16px;
            margin-bottom: 24px;
        }

        section {
            margin: 30px 0;
        }

        h2 {
            font-size: 20px;
            margin-bottom: 16px;
        }

        p {
            font-size: 15px;
            margin-bottom: 12px;
        }

        ul, ol {
            margin: 12px 0;
            
            li {
                margin: 8px 0;
                font-size: 15px;
            }
        }
    }
}

@media screen and (max-width: 480px) {
    .page_container {
        padding: 90px 12px 24px;
    }

    .title {
        font-size: 28px;
        margin-bottom: 20px;
    }

    .content {
        padding: 20px;
        border-radius: 12px;

        .effective_date {
            font-size: 15px;
            margin-bottom: 20px;
        }

        .intro {
            font-size: 15px;
            margin-bottom: 20px;
        }

        section {
            margin: 24px 0;
        }

        h2 {
            font-size: 18px;
            margin-bottom: 14px;
        }

        p {
            font-size: 14px;
            margin-bottom: 10px;
            line-height: 1.6;
        }

        ul, ol {
            margin: 10px 0;
            
            li {
                margin: 6px 0;
                font-size: 14px;
            }
        }
    }
}
