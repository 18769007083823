.information_box{
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;overflow-x: hidden;
}

.info1_box{
    position: absolute;
    top: 13%;
    left: 23.39%;
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.info1_title{
    color: white;
    font-family: 'Helvetica Neue';
    font-weight: 900;
    font-size: 32px;
    width: 400px;
}

.info1_text{
    color: white;
    font-family: 'Helvetica Neue';
    font-weight: 300;
    font-size: 20px;
    max-width: 600px;
}

.info2_box{
    position: absolute;
    top: 40.8%;
    left: 10%;
    display: flex;
    flex-direction: column;
}

.info2_title{
    color: white;
    font-family: 'Helvetica Neue';
    font-weight: 900;
    font-size: 22px;
    width: 350px;
    margin-bottom: 15px;
}

.info2_inner_box{
    display: flex;
    gap: 15px;
}

.info2_icon{
    background-image: url('../../../assets/icons/whatsPictographsIcon.svg');
    background-size: contain;
    background-repeat: no-repeat;
    width: 100px;
    height: 100px;
}

.info2_text1{
    color: white;
    font-family: 'Helvetica Neue';
    font-weight: 300;
    font-size: 20px;
    width: 230px;
    text-align: left;
}

.info2_text2{
    color: white;
    font-family: 'Helvetica Neue';
    font-weight: 300;
    font-size: 20px;
    width: 350px;
    text-align: left;
}

.info3_box{
    position: absolute;
    bottom: 12%;
    right: 9%;
    display: flex;
    flex-direction: column;
}

.info3_icon{
    background-image: url('../../../assets/icons/whyExceptionalIcon.svg');
    background-size: contain;
    background-repeat: no-repeat;
    width: 100px;
    height: 100px;
}

@media screen and (max-width: 1200px){
    .information_box{
        padding: 30px;
        padding-top: 120px;
        padding-bottom: 100px;
        display: flex;
        flex-direction: column;
        gap: 80px;
    }
    .info1_box{
        position: relative;
        top: 0;
        left: 0;
        right: 0;
    }
    .info1_title{
        font-size: 22px;
        width: 100%;
    }
    .info2_box{
        position: relative;
        top: 0;
        left: 0;
        right: 0;
    }
    .info2_title{
        width: 100%;
    }
    .info2_text1{
        width: calc(100% - 100px);
    }
    .info2_text2{
        width: 100%;
    }
}