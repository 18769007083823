.graphics_box{
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: -2;
    overflow-x: clip;
}

.element1{
    position: absolute;
    background: url('./elements/element1.svg');
    top: 34.93%;
    left: -1.71%;
    width: 4.46%;
    height: 21.37%;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}

.element2{
    position: absolute;
    background: url('./elements/element2.svg');
    top: 70.52%;
    left: 6.69%;
    width: 9.29%;
    height: 14.57%;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}

.element3{
    position: absolute;
    background: url('./elements/element3.svg');
    top: 22.1%;
    left: 10.39%;
    width: 13.75%;
    height: 12.83%;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}

.element4{
    position: absolute;
    background: url('./elements/element4.svg');
    top: 47.3%;
    left: 25.2%;
    width: 5.29%;
    height: 10.27%;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}

.element5{
    position: absolute;
    background: url('./elements/element5.svg');
    top: 78.09%;
    left: 33.09%;
    width: 5.45%;
    height: 6.33%;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}

.element6{
    position: absolute;
    background: url('./elements/element6.svg');
    top: calc(50% - (39.07% / 2));
    left: calc(50% - (33.69% / 2));
    width: 33.69%;
    height: 39.07%;
    background-repeat: no-repeat;
    z-index: 1;
    background-size: contain;
    background-position: center;
}

.element7{
    position: absolute;
    background: url('./elements/element7.svg');
    top: 17.04%;
    left: 58.8%;
    width: 1.67%;
    height: 2.96%;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}

.element8{
    position: absolute;
    background: url('./elements/element8.svg');
    top: 57.72%;
    left: 56.35%;
    width: 14.66%;
    height: 17.83%;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}

.element9{
    position: absolute;
    background: url('./elements/element9.svg');
    top: 35.4%;
    left: 74.54%;
    width: 1.78%;
    height: 5.99%;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}

.element10{
    position: absolute;
    background: url('./elements/element10.svg');
    top: 41.98%;
    left: 90.59%;
    width: 2%;
    height: 5.29%;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}

.element11{
    position: absolute;
    background: url('./elements/element11.svg');
    top: 3.23%;
    left: 80.31%;
    width: 13.98%;
    height: 31.21%;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}

.element12{
    position: absolute;
    background: url('./elements/element12.svg');
    top: 65.28%;
    left: 82.08%;
    width: 6.54%;
    height: 15.29%;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}

.element13{
    position: absolute;
    background: url('./elements/element13.svg');
    bottom: calc(54.85vw * -0.28);
    right: -20%;
    width: 54.85%;
    height: calc(54.85vw * 0.83);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}


@media screen and (max-width: 1200px){
    .element6{
        top: calc(50% - (78.14% / 2));
        left: calc(50% - (95% / 2));
        width: 95%;
        height: 78.14%;
    }
    .element13{
        width: 95%;
        height: calc(95vw * 0.83);
    }
}