@import "../../global.scss";

.header_button{
    @extend .unselectable;
    
    background-color: hsl(0, 0%, 3%);
    height: 100%;
    padding: 0px 20px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Helvetica Neue';
    font-weight: 300;
    font-size: 20px;
    cursor: pointer;
    box-shadow: 10px 10px 3px #00000029;
}