@import "../../../global.scss";

.positioning_box{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    position: relative;
    z-index: 2;
}

.donwload_box{
    background-color: #F2F2F2;
    padding-left: 30px;
    padding-right: 30px;
    height: 180px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 40px;
}

.download_text{
    font-family: 'Helvetica Neue';
    font-weight: 900;
    font-size: 42px;
}

.buttons_holder{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
}

.button_link{
    height: 50px;
    width: 200px;
    border-radius: 6px;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    img{
        height: 30px;
    }
    cursor: pointer;
}

.button_qr{
    @extend .button_link;
    width: 50px;
}

.mint_icon{
    height: 110px;
    width: 110px;
    border-radius: 5px;
    @extend .unselectable;
}
.donwload_box:has(.mint_icon){
    gap: 10px;
    padding-left: 15px;
    padding-right: 15px;
}

.mint_button{
    padding-top: 2px;
    height: 28px;
    width: 110px;
    border-radius: 10px;
    background-color: #FF77B9;
    font-family: 'Helvetica Neue';
    font-weight: 900;
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: white;
    cursor: pointer;
}


@media screen and (max-width: 1200px){
    .donwload_box{
        box-sizing: border-box;
        width: 70%;
        padding: 20px;
        height: fit-content;
    }

    .donwload_box:has(.mint_container){
        flex-direction: row;
    }

    .mint_icon{
        width: 100%;
        height: auto;
        aspect-ratio: 1 / 1;
    }
    .mint_button{
        width: 100%;
    }

    .mint_container{
        width: 100%;
    }

    .download_text{
        text-align: center;
        font-size: 32px;
    }

    .buttons_holder{
        flex-direction: column;
    }

    .button_qr{
        display: none;
    }

    .positioning_box{
        flex-direction: column;
    }
}