.graphics_box{
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: -2;
    overflow-x: clip;
}

.element1{
    position: absolute;
    background: url('./elements/element1.svg');
    top: 7.85%;
    left: 31.44%;
    width: 5.14%;
    height: 5.64%;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}

.element2{
    position: absolute;
    background: url('./elements/element2.svg');
    top: 40.81%;
    left: 4.75%;
    width: 12.07%;
    height: 9.76%;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}

.element3{
    position: absolute;
    background: url('./elements/element3.svg');
    top: 16.97%;
    left: 79.39%;
    width: 36.88%;
    height: 80.36%;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}

.element4{
    position: absolute;
    background: url('./elements/element4.svg');
    bottom: calc(50% - (62.44vw * 0.4 ));
    left: calc(50% - (62.44% / 2));
    width: 62.44%;
    height: calc(62.44vw * 0.66);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}

.element5{
    position: absolute;
    background: url('./elements/element5.svg');
    bottom: -125px;
    left: -6%;
    width: 42.75%;
    height: calc(42.75vw * 0.75);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}


@media screen and (max-width: 1200px){
    .element4{
        left: -50%;
        width: 200%;
        bottom: -10%;
        height: calc(200vw * 0.66);
    }
    .element5{
        width: 90%;
        height: calc(90vw * 0.75);
        left: -12%;
    }
}