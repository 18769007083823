.graphics_box{
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: -2;
    overflow: clip;
}

.element1{
    position: absolute;
    background: url('./elements/element1.svg');
    top: 78%;
    left: 10%;
    width: 2vw;
    height: 2vw;
    background-repeat: no-repeat;
    background-size: contain;
}

.element2{
    position: absolute;
    background: url('./elements/element2.svg');
    top: 10%;
    left: -10%;
    width: 120vw;
    aspect-ratio: 0.45;
    background-repeat: no-repeat;
    background-size: contain;
    z-index: -3;
}

.element3{
    position: absolute;
    background: url('./elements/element3.svg');
    top: 70%;
    left: 75%;
    width: 20vw;
    aspect-ratio: 0.78;
    background-repeat: no-repeat;
    background-size: contain;
    z-index: -2;
}