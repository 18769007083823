.footer {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: auto;
}

.social_wrapper {
    position: sticky;
    bottom: 0;
    background-color: rgb(252, 0, 6);
    padding: 8px 0;
    z-index: 1;
    min-height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.fixed_social {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgb(252, 0, 6);
    min-height: 44px;
    padding: 8px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
}

.footer_content {
    background-color: black;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}

.footer_social {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 600px;
    padding: 0 20px;
    box-sizing: border-box;
    gap: 0;

    a {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;

        &:not(:last-child)::after {
            content: '';
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            width: 1px;
            height: 24px;
            background-color: rgba(255, 255, 255, 0.3);
        }

        &::before {
            content: '';
            position: absolute;
            left: -12px;
            right: -12px;
            top: -8px;
            bottom: -8px;
            background: rgba(0, 0, 0, 0.15);
            border-radius: 6px;
            opacity: 0;
            transition: opacity 0.2s ease;
        }

        img {
            width: 24px;
            height: 24px;
            filter: brightness(0) invert(1);
            opacity: 0.85;
            transition: opacity 0.2s ease;
            position: relative;
            z-index: 1;
        }

        &:hover {
            transform: translateY(-1px);
            
            &::before {
                opacity: 1;
            }
            
            img {
                opacity: 1;
            }
        }
    }
}

.footer_button {
    color: white;
    text-decoration: none;
    font-family: 'Helvetica Neue';
    font-weight: 500;
    font-size: 16px;
    padding: 8px 20px;
    border: 2px solid rgba(255, 255, 255, 0.4);
    border-radius: 8px;
    background: rgba(255, 255, 255, 0.1);
    width: 220px;
    text-align: center;

    &:hover {
        background: rgba(255, 255, 255, 0.2);
        border-color: rgba(255, 255, 255, 0.8);
    }
}

.footer_copyright {
    color: white;
    font-family: 'Helvetica Neue';
    font-size: 14px;
    font-weight: 400;
    opacity: 0.9;
    text-align: center;
}