.page_container{
    width: 100%;
    height: 100vh;
    position: relative;
}

.scroll_holder{
    position: absolute;
    width: 100%;
    height: 50vh;
    top: 30%;
}

.title{
    position: absolute;
    width: 100%;
    height: 40px;
    top: 12%;
    left: 0;
    color: white;
    text-align: center;
    font-family: 'Helvetica Neue';
    font-weight: 900;
    font-size: 58px;
}

@media screen and (max-width: 1200px) {
    .title{
        font-size: 40px;
    }
}