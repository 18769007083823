.app {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    background: transparent; /* Changed from black to transparent */
}

.main-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    min-height: 0; /* Allow content to shrink */
}

@media screen and (max-width: 768px) {
    .app {
        min-height: -webkit-fill-available; /* Fix for mobile Safari */
    }
    
    .main-content {
        padding-bottom: 60px; /* Keep consistent padding for social bar */
    }
}
