.dropdown_layout{
    width: 200px;
    position: absolute;
    top: 70px;
    background-color: #2f2f2fae;
    padding: 10px;
    box-shadow: 10px 10px 3px #00000029;
    display: flex;
    flex-direction: column;
}

.dropdown_button{
    background-color: transparent;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Helvetica Neue';
    font-weight: 300;
    height: 25px;
    font-size: 15px;
}

.dropdown_button:hover{
    background-color: black;
    color: #ff77b9;
}