.graphics_box{
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: -2;
    overflow-x: clip;
}

.element1{
    position: absolute;
    background: url('./elements/element1.svg');
    top: calc(10%);
    left: -10%;
    width: 22%;
    height: calc(22vw*1.75);
    background-repeat: no-repeat;
    background-size: contain;
}

.element2{
    position: absolute;
    background: url('./elements/element2.svg');
    top: calc((100% - 13.2vw*5.45) / 2);
    right: -5%;
    width: 13.2%;
    height: calc(13.2vw*5.45);
    background-repeat: no-repeat;
    background-size: contain;
}

.element3{
    position: absolute;
    background: url('./elements/element3.svg');
    right: -5%;
    bottom: -20vw;
    width: 27%;
    height: calc(27vw*1.2);
    background-repeat: no-repeat;
    background-size: contain;
}