.page_container{
    height: 100%;
    width: 100%;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.title{
    position: absolute;
    top: 100px;
    font-size: 48px;
    font-weight: 900;
    color: white;
    font-family: 'Helvetica Neue';
}

.video_container{
    margin-top: 50px;
    width: 800px;
    height: 400px;
    iframe{
        width: 100%;
        height: 100%;
    }
}

@media screen and (max-width: 1200px) {

    .title{
        top: 100px;
        font-size: 36px;
    }
    .video_container{
        width: 90vw;
        height: 55vw;
    }
}