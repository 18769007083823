.page_container{
    width: 100%;
    height: 100vh;
    position: relative;
}

.main_container{
    position: absolute;
    width: 100%;
    top: 120px;
    bottom: 0;
}

.title{
    width: calc(100% - 300px);
    height: 80px;
    background-color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 150px;
    padding-right: 150px;

    div{
        background-color: black;
        height: 40px;
        width: 100px;
    }

    span{
        font-family: 'Helvetica Neue';
        font-weight: 900;
        font-size: 32px;
    }
}

.scroll_holder{
    width: 100%;
    height: calc(100% - 80px);
    background-color: wheat;
}

.left_arrow{
    position: absolute;
    width: 30px;
    height: 50px;
    left: 50px;
    bottom: calc((100% - 150px) / 2);
    background-image: url('../../assets/icons/leftArrow.svg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
    z-index: 10;
}

.right_arrow{
    position: absolute;
    width: 30px;
    height: 50px;
    right: 50px;
    bottom: calc((100% - 150px) / 2);
    background-image: url('../../assets/icons/rightArrow.svg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
    z-index: 10;
}

.person_card{
    width: calc(min(280px, 30vh));
    height: calc(100vh - 200px);
    background-color: var(--bgc);
    position: relative;
}

.person_card_photo{
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: bottom;
}

.person_card_name{
    color: var(--txtc);
    position: absolute;
    left: 0;
    right: 0;
    top: 10%;
    text-align: center;
    font-family: 'Helvetica Neue';
    font-weight: 900;
    font-size: 28px;
}

.person_card_position{
    color: var(--txtc);
    position: absolute;
    left: 0;
    right: 0;
    top: 30%;
    text-align: center;
    font-family: 'Helvetica Neue';
    font-weight: 300;
    font-size: 18px;
}

.person_card_socials{
    position: absolute;
    bottom: 15px;
    height: 40px;
    left: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 0;
    height: 40px;

    div{
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        background-color: white;
        border-radius: 5px;
        a{
            width: 40px;
            height: 40px;
            img{
                width: 40px;
                height: 40px;
            }
        }
    }
}

@media screen and (max-width: 1200px){
    .title{
        width: calc(100% - 40px);
        padding-left: 20px;
        padding-right: 20px;
        div{
            width: 40px;
            height: 20px;
        }
    }
}