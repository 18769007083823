.content_container{
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: center;
    align-items: center;
}


.content_box{
    width: 1000px;
    height: 500px;
    position: relative;
    display: flex;
    justify-content: center;
}

.title{
    font-family: 'Helvetica Neue';
    font-weight: 900;
    font-size: 50px;
    color: white;
}

.map{
    position: absolute;
    width: 90%;
    aspect-ratio: 2;
    bottom: 0;
    left: 0;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url('./map.svg');
    z-index: -1;
}

.map_back{
    position: absolute;
    width: 90%;
    aspect-ratio: 2;
    bottom: 5%;
    right: 0;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url('./map_back.svg');
    z-index: -2;
}

.apply_point{
    position: absolute;
    width: 10px;
    height: 10px;
    border: 5px solid #FE0000;
    border-radius: 10px;
    bottom: 60%;
    left: 65%;
    cursor: pointer;
    transform: scale(1.0);
    transition: all linear 0.05s;
}

.apply_point:hover{
    transform: scale(1.2)
}

.apply_box{
    position: absolute;

    right: 10%;
    top: 10%;

    width: 150px;
    height: 200px;

    background: black 0% 0% no-repeat padding-box;
    border: 6px solid #FE0000;
    border-radius: 20px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    p{
        color: white;
        font-family: 'Helvetica Neue';
        text-align: center;
        margin: 10px;
    }
    
    a{
        width: 80%;
        background: #242424 0% 0% no-repeat padding-box;
        border-radius: 5px;
        color: white;
        font-family: 'Helvetica Neue';
        text-align: center;
        font-size: 18px;
        padding: 5px;
        margin-bottom: 10px;
    }
}

.apply_box::before{
    content: "";
    height: 5px;
    width: 85px;
    background-color:#FE0000;
    position: absolute;
    bottom: 45%;
    left: -55.5%;
    transform: rotate(-30deg);
}

@media screen and (max-width: 1200px) {
    .content_box{
        width: 95%;
        height: 50vw;
    }
    .title{
        font-size: 35px;
    }

    .apply_box{
        left: calc(50% - 75px);
        top: 10%;
    }

    .apply_box::before{
        content: none;
    }
}